@import "../../variable";

button.btn.btn-primary {
    color: white;
    height: 3.5rem;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10rem;

    svg {
        width: 1rem;
        margin-left: 1rem;
        fill: white;
    }
}

button.btn-outline-primary:hover {
    color: white;
    svg {
        fill: white;
    }
}

button.btn-outline-primary {
    height: 3.5rem;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10rem;

    svg {
        width: 1rem;
        margin-left: 1rem;
        fill: $primary;
    }
}
