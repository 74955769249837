@import "./../../variable";

.saral-general-navbar {
    .row {
        display: flex;
        flex-direction: column;
    }
    padding: 30px 40px;

    img {
        border-left: 1px solid $line;
        width: 200px;
        padding-left: 20px;
    }

    svg {
        margin-right: 20px;
    }

    .fixed {
        flex: 0 0 auto;
    }
}

// .bottom-line {
//     border-bottom: $line 1px solid;
// }
