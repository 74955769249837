@import "../../variable";

div.redemption-card {
    // min-width: 600px;
    width: 70%;

    .card-body {
        padding: 5px 30px;
    }
    .g-recaptcha {
        transform: scale(0.9); /* Scale down the reCAPTCHA */
        transform-origin: 0 0; /* Adjust the origin to avoid clipping */
        width: 100% !important; /* Ensure the container width scales */
    }

    svg {
        height: 50px;
        width: 50px;
        margin: 20px 0px;
    }

    .mt-10 {
        margin: 10px;
    }

    .mb-10 {
        margin: 10px;
    }

    .card-btn {
        display: flex;
        justify-content: center;
        padding: 15px;
        padding-top: 5vh;
        margin-bottom: 40px;
    }
}
