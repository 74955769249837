@import "./../../variable";

.saral-navbar {
    padding: 30px 40px;
    padding-bottom: 30px;
}

// .bottom-line {
//     border-bottom: $line 1px solid;
// }
