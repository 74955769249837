@import "../../variable";

.footer-card {
    width: 70%;
    max-width: 70vw;
    margin-bottom: 30px;
    padding: 1rem;

    .card-body {
        padding: 2.5vw;
    }

    .banner-text {
        font-family: "Katibeh", sans-serif;
        font-size: 50px;
        font-weight: 400;
        line-height: 80%;
        letter-spacing: -2px;
        color: $secondary;
    }

    .card-btn {
        padding-top: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
}

// .footer-card {
//     max-width: 400px;
//     .card-body {
//         max-height: 12vw;
//         padding: 2.5vw;
//     }

//     svg {
//         height: 40px;
//         width: 40px;
//         margin: 20px 0px;
//     }

//     .card-btn {
//         display: flex;
//         justify-content: flex-start;
//         align-items: flex-end;
//     }

//     .banner-text {
//         font-family: "Katibeh", sans-serif;
//         font-size: 50px;
//         font-weight: 400;
//         line-height: 80%;
//         letter-spacing: -2px;
//         color: $secondary;
//     }
// }
