@import "../../variable";

.footer-colored {
    background-color: $secondary;
    height: 20vh;
    display: flex;
    flex-direction: column;
}

.footer-text {
    color: white;
    padding-bottom: 20px;
    padding-left: 40px;
}

.flex-grow {
    flex: 1; /* Allows the row to expand and fill available space */
}

.bottom-row {
    margin-top: auto; /* Pushes this row to the bottom */
}
