@import "./variable";
.App {
    text-align: center;
    color: $text-color;
}

/* App.css */
input.form-check-input[type="checkbox"] {
    border-color: $primary;
    border-radius: 5rem;
}

input.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
    border-radius: 5rem;
}

input.form-check-input:focus {
    box-shadow: none;
    outline: none;
}

.logo {
    fill: $primary;
}

input.form-control::placeholder {
    color: rgba(31, 31, 31, 0.5);
}



.form-label {
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: $secondary;
}

input.form-control {
    height: 4rem;
    background-color: $input-bg;
    border: none;
    border-radius: 1rem;
    font-size: 1.25rem;
    padding: 1rem;
}

span.input-group-text {
    background-color: white;
    border: 5px solid $input-bg;
    border-radius: 1rem;
    text-align: center;
}

a {
    text-decoration: none !important;
}
