@import "../../variable";

div.voucher-detail-card {
  width: 80%;
  padding: 4%;
  border: 1px solid $line;
  .card-body {
    // min-height: 10vw;
    padding: 1rem;
  }
  h4,
  h6 {
    color: $secondary;
    font-family: "Inter", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  h6 {
    font-size: 0.9rem;
    font-weight: 400;
  }

  .card-btn {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .heading {
    color: $primary;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font: 500 25px Inter, sans-serif;
    display: flex;
    justify-content: center;
    margin: 4% 0 0 0;
    padding: 4%;
  }

  .text-style-bold {
    font-size: 1.2rem;
    font-weight: bold;
    font: 700 18px Inter, sans-serif;
    letter-spacing: 0.5px;
  }
  .text-style {
    font-size: 1.2rem;
    font-weight: bold;
    font: 500 18px Inter, sans-serif;
    letter-spacing: 1px;
  }

  .subtext-style {
    font-size: 1.1rem;
    font-weight: 500;
    font: 500 18px Inter, sans-serif;
  }

  .card-btn1 {
    background-color: #fff2e8;
    border-radius: 20px;
    margin-top: 20px;
    padding: 6%;
  }
  .card-btn2 {
    border-radius: 20px;
    padding: 2% 2% 8% 2%;
  }
  .card-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
