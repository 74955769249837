@import "../variable";

.footer {
    margin-top: 3rem;
    .footer-cards {
        background: linear-gradient(top, #fff 50%, $secondary 50%);
        background: linear-gradient(to bottom, #ffffff00 50%, $secondary 50%);
        display: flex;
        justify-content: center;
        .col,
        .col-md-12,
        .col-md-6,
        .col-md-8 {
            padding: 0;
            display: flex;
            justify-content: center;

            .footer-card {
                width: 70%;
                margin-bottom: 30px;
                padding: 1rem;

                .card-body {
                    padding: 2.5vw;
                }

                .banner-text {
                    font-family: "Katibeh", sans-serif;
                    font-size: 50px;
                    font-weight: 400;
                    line-height: 80%;
                    letter-spacing: -2px;
                    color: $secondary;
                }

                .card-btn {
                    padding-top: 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                }
            }
        }
    }

    .footer-links {
        color: white;
        background-color: $secondary;
        display: flex;
        justify-content: space-between;
        padding: 30px 40px;
        padding-top: 80px;
    }
}

.error{
  
    text-align: center;
    margin-bottom: 5px;
}

.error_container {
    font-size: 16px;
    width: 100%;
    border-radius: 8px;
    height: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;

    .close {
        position: relative;
        right: -8px;
        top: -8px

    }
    
    .error_content {

       
        margin: 10px 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
            color: rgb(187, 23, 23);
            width: 30px;
            height: 30px;
            margin-right: 8px;
        }
    }
}

.error-dialog.modal-dialog {
    display: flex;
    transform: none;
    height: 70vh;
    align-items: center;
    justify-content: center;
    width: 30vw;
    min-width: 300px;
    
}

div.modal {
    display: flex !important;
    justify-content: center;

   .modal-content{
    border: none;
    background-color:     #f8f9fa;

   }
}

.dotted-background {
    background-image: radial-gradient(rgba(0, 0, 0, 0.04) 3px, transparent 0px);
    background-size: 40px 40px, 100% 20%;

    .landing {
        height: 100vh;
        display: flex;
        flex-direction: column;

        .navbar {
            flex: 1;
        }

        .homepage-top {
            padding: 0px 5vw;
            flex: 10;

            .row {
                display: flex;
                height: 100%;
            }

            .col,
            .col-md-6 {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .redemption-card {
                    // min-width: 600px;
                    width: 70%;

                    .card-body {
                        padding: 5px 30px;
                        .logo {
                            height: 50px;
                            width: 50px;
                            margin: 20px 0px;
                        }
                    }
                    .g-recaptcha {
                        transform: scale(0.9); /* Scale down the reCAPTCHA */
                        transform-origin: 0 0; /* Adjust the origin to avoid clipping */
                        width: 100% !important; /* Ensure the container width scales */
                    }

                    .mt-10 {
                        margin: 10px;
                    }

                    .mb-10 {
                        margin: 10px;
                    }

                    .card-btn {
                        display: flex;
                        justify-content: center;
                        padding: 15px;
                        padding-top: 5vh;
                        margin-bottom: 40px;
                    }
                }
            }

            .banner-text {
                font-family: "Katibeh", sans-serif;
                font-size: 75px;
                font-weight: 400;
                line-height: 80%;
                letter-spacing: -2px;
                color: $secondary;
            }
        }
    }
}

.create-voucher-page {
    padding: 60px 50px 80px 50px;

    .form-card {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    h2,
    h5 {
        color: $secondary;
    }

    .border-top {
        border-top: solid 1px $line;
    }

    // .block {
    //   display: block;
    // }
    .col {
        // display: flex;
        justify-content: center;
    }
    .accordion {
        padding-top: 3rem;
        padding-bottom: 6rem;

        .accordion-button {
            line-height: 2rem;
            padding: 10px;
        }
    }

    footer {
        height: 10vh;
    }

    h2.card-heading {
        font-family: "Katibeh", sans-serif;
        font-weight: 400;
        color: $secondary;
        font-size: 40px;
        text-align: center;
        margin: 20px 0px;
    }

    h2 {
        font-family: "Katibeh", sans-serif;
        font-weight: 500;
        font-size: 4rem;
        letter-spacing: -1px;
        line-height: 2rem;
        margin-top: 30px;
    }

    .accordion-button:focus,
    .accordion-button:not(.collapsed) {
        box-shadow: none;
        outline: none;
        background-color: transparent;
        color: $secondary;
    }

    .accordion-header {
        font-family: "Katibeh", sans-serif;
        font-weight: 400;
        color: $secondary;
    }

    .accordion-item {
        border-right: 0px;
        border-left: 0px;
        border-top: 1px solid $line;
        border-bottom: 1px solid $line;
        padding-bottom: 20px;
    }

    .accordion-item:first-of-type {
        border-top: 0px;
        // border-bottom: 0px;
    }

    .accordion-item:last-of-type {
        // border-top: 0px;
        border-bottom: 0px;
    }
    .accordion-body {
        padding-top: 3rem;
    }

    .accordion-button {
        font-size: 40px;
        font-weight: 200;

        color: $secondary;
    }
    .accordion-button:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2326866d'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
}

@media (min-width: 787px) {
    .md-show {
        display: block;
    }

    .md-hide {
        display: none !important;
    }
}

@media (max-width: 450px) {
    .sm-hide {
        display: none;
    }

    button.sm-button.btn-outline-primary {
        height: auto;
        display: flex;
        flex-direction: column;
        border-radius: 2rem;
        padding: 10px 20px;
    }
}

@media (max-width: 786px) {
    /* sm */

    .footer {
        .footer-cards {
            display: flex;
            gap: 50px;

            background: linear-gradient(top, #fff 80%, $secondary 80%);
            background: linear-gradient(to bottom, #ffffff00 80%, $secondary 80%);

            .card {
                width: 90vw;
            }
        }

        .footer-links {
            flex-direction: column;
            align-items: center;
            gap: 20px;
            padding-top: 30px;
        }
    }
    .dotted-background {
        .landing {
            height: 100%;

            .homepage-top {
                /* sm */
                .col,
                .col-md-6 {
                    justify-content: center;
                    .redemption-card {
                        // min-width: 600px;
                        width: 80%;
                    }
                }

                .banner-text {
                    padding: 2rem 2rem;
                }
            }
        }
    }

    .md-show {
        display: none;
    }

    .md-hide {
        display: block;
    }

    .create-voucher-page {
        padding: 60px 50px 80px 50px;

        .form-card {
            justify-content: center;
        }
    }
}

.banner {
    display: flex;
    width: 100%;
    height: 40vh;
    img {
        width: 100%;
        object-fit: cover;
    }
}

.success-page {
    padding: 30px 50px 80px 50px;

    min-height: 80vh;

    h2,
    h5 {
        color: $secondary;
    }

    h2 {
        font-family: "Katibeh", sans-serif;
        font-weight: 500;
        font-size: 4rem;
        letter-spacing: -1px;
        line-height: 2rem;
        margin-top: 30px;
    }

    .border-top {
        border-top: solid 1px $line;
    }

    .success-card {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 50px;
    }

    .card-body {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    svg {
        margin-top: 50px;
    }

    .confirmation {
        width: 60%;
        text-align: center;
        align-items: center;
    }

    .logo-text {
        font-size: 12px;
    }

    .bank-logo {
        flex-direction: column;
        align-items: center;
    }
}
